<template>
  <div class="card full-width">

    <div class="card-header border-0 pt-6 full-width">
      <!--begin::Card title-->

      <div class="row full-width">
        <div class="col-md-6 v-row">
          <el-input
              v-model="filters.search"
              class="m-2"
              size="large"
              placeholder="Please Input"
              :suffix-icon="Search"
          ></el-input>
        </div>
        <div class="col-md-6 fv-row">
          <el-select clearable v-model="filters.type" class="m-2 select-display-block" placeholder="Select Status"
                     size="large">
            <el-option
                v-for="item in filterOptions.types"
                :label="item.label"
                :value="item.value"
                :key="item.value"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="card-title full-width">

      </div>
    </div>
    <div class="card-body pt-0">
      <Datatable
          class="table-button-actions-width"
          :total="total"
          :rowsPerPage="perPage"
          :loading="loading"
          :table-data="tableData"
          :table-header="tableHeader"
          :enable-items-per-page-dropdown="true"
          @current-change="onCurrentChange"
          @sort="sort"
          @items-per-page-change="changePerPage"
      >
        <template v-slot:cell-id="{ row: data }">{{ data.id }}</template>
        <template v-slot:cell-name="{ row: data }">{{ data.name }}</template>
        <template v-slot:cell-email="{ row: data }">{{ data.email }}</template>
        <template v-slot:cell-type="{ row: data }">
          <span class="badge fs-7 fw-bolder" :class="`badge-light-${data.type.class}`">{{
              data.type.label
            }}</span>
        </template>
        <template v-slot:cell-message="{ row: data }">{{ data.message }}</template>
        <template v-slot:cell-date="{ row: data }">{{ data.date }}</template>

      </Datatable>
    </div>
  </div>
</template>

<script>
import {defineComponent, ref, onMounted, watch, reactive} from "vue";
import Datatable from "@/components/kt-datatable/KTDatatable";
import {setCurrentPageBreadcrumbs} from "@/core/helpers/breadcrumb";
import ApiAxiosService from "@/core/services/ApiAxiosService";
import {Search, Edit, Delete, View} from '@element-plus/icons-vue'
import {handleError, handleSuccess} from "@/core/helpers/response";
import {APIs} from "@/store/enums/ApiEnums";
import CheckboxTableHeader from "@/components/admin/general/CheckboxTableHeader";
import {confirmDelete, confirmGeneralMessage} from "@/core/helpers/custom_notification";
import {useRouter, useRoute} from 'vue-router'
import Swal from "sweetalert2/dist/sweetalert2.js";
import {getCities} from "@/core/helpers/remote_data";

export default defineComponent({
  name: "contacts-list",
  components: {
    Datatable, CheckboxTableHeader
  },

  setup() {
    const router = useRouter();
    const tableHeader = ref([
      {name: "ID", key: "id", sortable: true,},
      {name: "Name", key: "name", sortable: true,},
      {name: "Email", key: "email", sortable: true,},
      {name: "Type", key: "type", sortable: true,},
      {name: "Message", key: "message", sortable: true,},
      {name: "Date", key: "date", sortable: true,},

    ]);

    const tableData = ref([]);
    const loading = ref(true);
    const total = ref(0);
    const currentPage = ref(1);
    const perPage = ref(0);
    const filters = ref({
      search: '',
      type: '',
      page: 1,
      per_page: 10
    });

    const filterOptions = ref({types: []});

    onMounted(() => {
      setCurrentPageBreadcrumbs("Contacts", []);
    });

    const fetchFilerOptions = async () => {
      try {
        let response = await ApiAxiosService.get(APIs.CONTACT.filter_options);
        filterOptions.value.types = response.data.data.types;
      } catch (e) {
        handleError(e)
      }
    }

    const fetchData = async () => {
      loading.value = true;
      try {
        filters.page = currentPage.value;
        let response = await ApiAxiosService.get(APIs.CONTACT.list, filters.value);
        let data = response.data.data;
        tableData.value = data.contacts;
        total.value = data.pagination_options.last_page;
        currentPage.value = data.pagination_options.currentPage;
        perPage.value = 1;
        loading.value = false;
      } catch (error) {
        handleError(error)
      }

    };

    const onCurrentChange = (value) => {
      filters.value.page = value;
    };
    const changePerPage = (value) => {
      filters.value.per_page = value;
    }

    const sort = (data) => {
    }
    watch(filters.value, (currentValue, oldValue) => {
      fetchData();
    });

    fetchData();
    fetchFilerOptions();

    return {
      tableData,
      tableHeader,
      loading,
      fetchData,
      total,
      perPage,
      onCurrentChange,
      changePerPage,
      filters,
      sort,
      filterOptions,
      Search

    };
  },

});
</script>
